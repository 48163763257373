@import url(https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat+Subrayada:wght@400;700&display=swap);
@import url(https://use.fontawesome.com/releases/v5.0.10/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Lato:300);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat+Subrayada:wght@400;700&display=swap);
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.header_container {
  position: fixed;
  top: 0;
  height: 120px;
  width: 100%;
}

.top_header {
  height: 3.5em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgb(245, 195, 66);
}

.bottom_header {
  height: 4em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9d677;
  border-bottom: 2px solid black;
  position: relative;
  z-index: 100;
}

.logo_holder {
  width: 250px;
  height: 100%;
  padding-left: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_holder h1 {
  display: flex;
  height: 100%;
  font-size: 2em;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  padding-top: 15px
}

.logo_holder h1 span {
  font-weight: 700;
  justify-content: center;
}

.logo_holder img {
  padding-top: 0.5em;
  padding-left: 1em;
}

.top_button {
  width: 50%;
}

.header_item {
  display: flex;
  justify-content: center;
}

.header_item {
  display: flex;
  justify-content: center;
}

.navigation {
  flex-grow: 1;
  height: 100%;
  padding-right: 20px;
}

.navigation a {
  height: 100%;
  display: table;
  float: left;
  padding: 0px 15px;
}

.navigation a li {
  display: table-cell;
  vertical-align: middle;
  font-size: 21px;
  font-weight: 400;
  color: black;
  font-family: "Fira Sans Extra Condensed", sans-serif;
}

.piece_selected {
  background-color: rgb(245, 195, 66) !important;
}

.easter_hide {
  visibility: hidden;
}

.piece_select {
  display: flex;
  align-items: center;
  height: 30px;
  flex-shrink: 0;
  padding-left: 15px;
}

.piece_select span {
  display: table
}

.piece_select button {
  cursor: pointer;
  background-color: rgb(249, 215, 120);
  border: 0.1em solid black;
  margin: 0 0 0 0.2em;
  border-radius: 0.5em;
  padding: 0.5em 0 0.16em 0;
  outline: none;
  font-family: "Century Gothic";
  transform: scale(0.9);
}

.piece_select h1 {
  font-size: 21px;
  font-weight: 300;
  padding-right: 20px;
  font-family: "Fira Sans Extra Condensed", sans-serif;
}

.algo_select {
  display: flex;
  align-items: center;
  width: 400px;
  height: 30px;
  flex-shrink: 0
}

.algo_select h1 {
  font-size: 21px;
  padding-right: 20px;
  font-weight: 300;
  font-family: "Fira Sans Extra Condensed", sans-serif;
}

.selectdiv {
  position: relative;
  /*Don't really need this just for demo styling*/
  vertical-align: middle;
  display: table-cell;
}

.footer {
  position: fixed;
  bottom: 0;
  background-color: #f5c342;
  height: 1.75em;
  width: 100%;
  color: black;
  font-size: 21px;
  font-weight: 400;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  border-top: 2px solid black;
  vertical-align: middle;
  padding-top: 4px;
  text-align: center;
}

.heart {
  color: red;
}

.node {
  width: 1.111vw;
  height: 1.111vw;
  outline: 0px solid #bdbdbd;
  display: inline-block;
  box-shadow: 0 1px 0 #bdbdbd inset, 1px 0 0 rgb(189, 189, 189) inset,
    0 1px 0 rgb(189, 189, 189) inset;
  background-color: #fffafa;
}

.node-color {
  background-color: rgb(232, 232, 232);
  outline: 0px solid rgb(189, 189, 189);
  border-top: 1px black;
  display: inline-block;
}

.node-finish {
  background-color: red;
  margin: 0;
  padding: 0;
}

.node-start {
  background-color: green;
  margin: 0;
  padding: 0;
}

.node-visited {
  animation-name: visitedAnimation;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes visitedAnimation {
  
  0% {
    transform: scale(.5);
    background-color: rgba(66, 0, 0, 0.75);
    border-radius: 100%;
  }

  50% {
    background-color: rgba(217, 17, 17, 0.75);
  }

  75% {
    transform: scale(1.2);
    background-color: rgba(255, 135, 65, 0.75);
  }

  100% {
    transform: scale(1);
    background-color: rgba(255, 196, 70, 0.75);
  }
}

.node-wall {
  animation-name: wallAnimation;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation-play-state: running;
  background-color: rgb(12, 53, 71);
}

@keyframes wallAnimation {
  0% {
    transform: scale(0.3);
    background-color: rgb(12, 53, 71);
  }

  50% {
    transform: scale(1.2);
    background-color: rgb(12, 53, 71);
  }

  100% {
    transform: scale(1);
    background-color: rgb(12, 53, 71);
  }
}

.prev-wall {
  animation-name: inverseWallAnimation;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation-play-state: running;
}

@keyframes inverseWallAnimation {
  100% {
    transform: scale(0.3);
    background-color: rgb(12, 53, 71);
  }

  50% {
    transform: scale(1.2);
    background-color: rgb(12, 53, 71);
  }

  0% {
    transform: scale(1);
    background-color: rgb(12, 53, 71);
  }
}

.node-shortest-path {
  animation-name: shortestPath;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes shortestPath {
  0% {
    transform: scale(0.6);
    background-color: red;
  }

  50% {
    transform: scale(1.2);
    background-color: red;
  }

  100% {
    transform: scale(1);
    background-color: red;
  }
}

.grid {
  z-index: 100;
  margin-top: 120px;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.row {
  display: flex;
}

.body {
  background-color: rgb(207, 207, 207);
  width: 100%;
  height: 100%;
}

.current {
  font-size: 15px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  padding-top: 5px;
}

.App {
  text-align: center;
}

