.node {
  width: 1.111vw;
  height: 1.111vw;
  outline: 0px solid #bdbdbd;
  display: inline-block;
  box-shadow: 0 1px 0 #bdbdbd inset, 1px 0 0 rgb(189, 189, 189) inset,
    0 1px 0 rgb(189, 189, 189) inset;
  background-color: #fffafa;
}

.node-color {
  background-color: rgb(232, 232, 232);
  outline: 0px solid rgb(189, 189, 189);
  border-top: 1px black;
  display: inline-block;
}

.node-finish {
  background-color: red;
  margin: 0;
  padding: 0;
}

.node-start {
  background-color: green;
  margin: 0;
  padding: 0;
}

.node-visited {
  animation-name: visitedAnimation;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes visitedAnimation {
  
  0% {
    transform: scale(.5);
    background-color: rgba(66, 0, 0, 0.75);
    border-radius: 100%;
  }

  50% {
    background-color: rgba(217, 17, 17, 0.75);
  }

  75% {
    transform: scale(1.2);
    background-color: rgba(255, 135, 65, 0.75);
  }

  100% {
    transform: scale(1);
    background-color: rgba(255, 196, 70, 0.75);
  }
}

.node-wall {
  animation-name: wallAnimation;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation-play-state: running;
  background-color: rgb(12, 53, 71);
}

@keyframes wallAnimation {
  0% {
    transform: scale(0.3);
    background-color: rgb(12, 53, 71);
  }

  50% {
    transform: scale(1.2);
    background-color: rgb(12, 53, 71);
  }

  100% {
    transform: scale(1);
    background-color: rgb(12, 53, 71);
  }
}

.prev-wall {
  animation-name: inverseWallAnimation;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation-play-state: running;
}

@keyframes inverseWallAnimation {
  100% {
    transform: scale(0.3);
    background-color: rgb(12, 53, 71);
  }

  50% {
    transform: scale(1.2);
    background-color: rgb(12, 53, 71);
  }

  0% {
    transform: scale(1);
    background-color: rgb(12, 53, 71);
  }
}

.node-shortest-path {
  animation-name: shortestPath;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes shortestPath {
  0% {
    transform: scale(0.6);
    background-color: red;
  }

  50% {
    transform: scale(1.2);
    background-color: red;
  }

  100% {
    transform: scale(1);
    background-color: red;
  }
}
