@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat+Subrayada:wght@400;700&display=swap");
@import "https://use.fontawesome.com/releases/v5.0.10/css/all.css";
@import "https://fonts.googleapis.com/css?family=Lato:300";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,500&display=swap");

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.header_container {
  position: fixed;
  top: 0;
  height: 120px;
  width: 100%;
}

.top_header {
  height: 3.5em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgb(245, 195, 66);
}

.bottom_header {
  height: 4em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9d677;
  border-bottom: 2px solid black;
  position: relative;
  z-index: 100;
}

.logo_holder {
  width: 250px;
  height: 100%;
  padding-left: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_holder h1 {
  display: flex;
  height: 100%;
  font-size: 2em;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  padding-top: 15px
}

.logo_holder h1 span {
  font-weight: 700;
  justify-content: center;
}

.logo_holder img {
  padding-top: 0.5em;
  padding-left: 1em;
}

.top_button {
  width: 50%;
}

.header_item {
  display: flex;
  justify-content: center;
}

.header_item {
  display: flex;
  justify-content: center;
}

.navigation {
  flex-grow: 1;
  height: 100%;
  padding-right: 20px;
}

.navigation a {
  height: 100%;
  display: table;
  float: left;
  padding: 0px 15px;
}

.navigation a li {
  display: table-cell;
  vertical-align: middle;
  font-size: 21px;
  font-weight: 400;
  color: black;
  font-family: "Fira Sans Extra Condensed", sans-serif;
}

.piece_selected {
  background-color: rgb(245, 195, 66) !important;
}

.easter_hide {
  visibility: hidden;
}

.piece_select {
  display: flex;
  align-items: center;
  height: 30px;
  flex-shrink: 0;
  padding-left: 15px;
}

.piece_select span {
  display: table
}

.piece_select button {
  cursor: pointer;
  background-color: rgb(249, 215, 120);
  border: 0.1em solid black;
  margin: 0 0 0 0.2em;
  border-radius: 0.5em;
  padding: 0.5em 0 0.16em 0;
  outline: none;
  font-family: "Century Gothic";
  transform: scale(0.9);
}

.piece_select h1 {
  font-size: 21px;
  font-weight: 300;
  padding-right: 20px;
  font-family: "Fira Sans Extra Condensed", sans-serif;
}

.algo_select {
  display: flex;
  align-items: center;
  width: 400px;
  height: 30px;
  flex-shrink: 0
}

.algo_select h1 {
  font-size: 21px;
  padding-right: 20px;
  font-weight: 300;
  font-family: "Fira Sans Extra Condensed", sans-serif;
}

.selectdiv {
  position: relative;
  /*Don't really need this just for demo styling*/
  vertical-align: middle;
  display: table-cell;
}
