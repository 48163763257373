.footer {
  position: fixed;
  bottom: 0;
  background-color: #f5c342;
  height: 1.75em;
  width: 100%;
  color: black;
  font-size: 21px;
  font-weight: 400;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  border-top: 2px solid black;
  vertical-align: middle;
  padding-top: 4px;
  text-align: center;
}

.heart {
  color: red;
}
