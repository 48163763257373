@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat+Subrayada:wght@400;700&display=swap");

.grid {
  z-index: 100;
  margin-top: 120px;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.row {
  display: flex;
}

.body {
  background-color: rgb(207, 207, 207);
  width: 100%;
  height: 100%;
}

.current {
  font-size: 15px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  padding-top: 5px;
}

.App {
  text-align: center;
}
